<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA' && showQrScaner==='block'"
        class="qrcode-wrap"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <v-row>
        <v-col
          :class="{'pr-0': $store.getters.getMobileModel !== 'PDA' }"
          :cols="$store.getters.getMobileModel !== 'PDA' ? 4 : 5"
        >
          <v-text-field
            id="input-qrkey"
            v-model="qrKey"
            label="파렛트번호"
            autocomplete="off"
            placeholder="QR 스캔"
            @keyup.enter.prevent="onEnterPcode"
          />
        </v-col>
        <v-col
          v-if="$store.getters.getMobileModel !== 'PDA'"
          class="pb-0 pt-7 pl-0"
          cols="1"
        >
          <v-icon
            @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
          >
            mdi-camera
          </v-icon>
        </v-col>
        <v-col cols="7">
          <v-autocomplete
            id="input-pcode"
            ref="refPcode"
            v-model="selectedPCode"
            clearable
            :items="productInfoList"
            label="품번"
            item-text="품번"
            item-value="품번"
            auto-select-first
            :disabled="isStorageMag"
            @change="onProductListChange"
            @keyup.enter.prevent="onEnterProductList"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pt-0"
          cols="6"
        >
          <v-text-field
            id="input-reqty"
            v-model="inputReQty"
            label="제생산수량"
            type="number"
            autocomplete="off"
            clearable
            @keyup.enter.prevent="onEnterReQty"
          />
        </v-col>
        <v-col
          class="pt-0"
          cols="6"
        >
          <v-text-field
            id="input-qty"
            v-model="inputQty"
            autocomplete="off"
            label="생산수량"
            type="number"
            clearable
            @keyup.enter.prevent="onEnterQty"
          />
        </v-col>
      </v-row>
      <v-row
        class="mt-0 mb-0 pt-0"
      >
        <v-col
          cols="12"
        >
          <v-btn
            width="100%"
            color="primary"
            @click="onEnterQty"
          >
            실적 등록
          </v-btn>
        </v-col>
      </v-row>
      <v-text-field
        v-model="selectedRow.품명"
        label="품명"
        autocomplete="off"
        :readonly="true"
        filled
      />
      <div style="margin-top:10px; background-color:white;">
        <dx-data-grid
          ref="refGoodsInputGrid"
          :data-source="goodsInputListInfo"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="gridHeightSub"
          :focused-row-enabled="true"
          key-expr="입고키"
          :focused-row-key.sync="focusedRowKey"
          @row-removed="onRowRemoved"
        >
          <DxEditing
            :allow-deleting="true"
            :allow-updating="false"
            :allow-adding="false"
            :use-icons="true"
            mode="row"
          />
          <DxColumn
            :allow-editing="false"
            caption="LOT"
            data-field="입고키"
            sort-order="desc"
            :min-width="70"
          />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
            :min-width="120"
          />
          <DxColumn
            :allow-editing="false"
            caption="수량"
            data-field="양품"
            format="#,##0"
            :min-width="70"
          />
          <DxColumn
            :allow-editing="false"
            caption="재생산"
            data-field="재생산수량"
            format="#,##0"
            :min-width="70"
          />
          <DxColumn
            width="50"
            :buttons="editButtons"
            type="buttons"
          />
          <DxSummary>
            <DxTotalItem
              value-format="#,##0"
              show-in-column="품번"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
    </v-container>
  </div>
</template>
<script>
import DxDataGrid, { DxScrolling, DxColumn, DxEditing, DxPaging, DxSorting, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'

export default {
  name: 'RegPallet',
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSummary,
    DxTotalItem,
    DxEditing,
    QrCodeScanComponent
  },
  props: {
    goodsType: {
      type: String,
      default: 'S0100'
    }
  },
  data: function () {
    return {
      userInfo: null,
      systemParam: null,
      deliveryListInfo: [],
      focusedRowKey: null,
      selectedRow: {},
      focusedRowKeyOrder: null,
      date: null,
      modal: false,
      qrKey: null,
      inputQty: null,
      inputReQty: 0,
      qrPCode: null,
      gridHeightSub: null,
      goodsInputListInfo: [],
      datePickerWidth: '350px',
      editButtons: ['delete'],
      unitLookup: null,
      orderDate: null,
      selectedPCode: null,
      productInfoList: [],
      badCode: null,
      isSaving: false,
      // qrcode scan
      showQrScaner: 'none'
    }
  },
  computed: {
    isStorageMag () {
      const temp = this.$store.getters.getSysParam
      return this.$_.findWhere(temp, { key: '생산실적등록' }).value === 'qrcode'
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.unitLookup = this.$store.getters.getBaseDataByType(ConstDef.단위)
    this.datePickerWidth = this.getDatePickerWidth()
    this.systemParam = this.$store.getters.getSysParam
    const where = this.$_.findWhere(this.systemParam, { key: '제품유효기간' })
    this.date = AppLib.calcDateFromToday(parseInt(where.value))
    this.refreshInputGoodsList() // 금일 실적등록 리스트
    this.refreshProductInfo()
  },
  mounted () {
    this.gridHeightSub = (this.$Q('html').height() - 334)
    window.addEventListener('resize', this.handleResize)
    this.initInput()
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    getDateOrderDate () {
      const startTime = this.$_.findWhere(this.$store.getters.getSysParam, { key: '주간생산시작시간' }).value
      const time = AppLib.getNowTime()
      if (time >= '00:00' && time < startTime) {
        this.orderDate = AppLib.calcDateFromToday(-1)
      } else {
        this.orderDate = AppLib.getToday()
      }
    },
    getDatePickerWidth () {
      return `${AppLib.getDatePickerWidth(this.$store)}px`
    },
    getUnitBom () {
      if (this.selectedRow.단위 === undefined) return
      return this.$_.findWhere(this.unitLookup, { 코드: this.selectedRow.단위 }).코드명
    },
    getUnitText () { // 구매단위
      if (this.selectedRow.구매단위 === undefined) return
      return this.$_.findWhere(this.unitLookup, { 코드: this.selectedRow.구매단위 }).코드명
    },
    handleResize (event) {
    },
    async addInventory () {
      if (this.isSaving) return
      this.getDateOrderDate()
      let 가격정보 = null
      await this.$_sp.runNoEncodeFindProc('spFindAllProductionSaleAndSubcontractPrice', { 품번: this.selectedPCode, 기준일: AppLib.getNow() })
        .then((data) => {
          가격정보 = this.$_sp.MakeModel(data)
          if (가격정보.length <= 0) {
            this.$snotify.info('판매가 또는 외주가공비가 조회되지않았습니다.')
          }
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      const param = {
        생산기준일: this.orderDate,
        입고키: this.qrKey,
        품번: this.selectedRow.품번,
        양품: parseInt(this.inputQty),
        불량: 0,
        재생산수량: parseInt(this.inputReQty),
        판매가: 가격정보[0].판매가,
        외주가공비: 가격정보[0].외주가공비,
        createid: this.userInfo.userId,
        updateid: this.userInfo.userId
      }
      this.isSaving = true
      if (this.qrKey !== 0) {
        await this.$_sp.runNoEncodeFindSqlProc('생산실적', { 입고키: this.qrKey })
          .then((data) => {
            if (this.$_sp.MakeModel(data).length > 0) {
              this.isSaving = false
            }
          })
          .catch(error => {
            this.isSaving = false
            this.initInput()
            if (error.response.status === ConstDef.GENERRAL_ERROR) {
              this.$snotify.error(error.response.data.message)
            } else {
              this.$snotify.error(`에러코드 : ${error.response.status}`)
            }
          })
      }

      if (this.isSaving === false) {
        this.$snotify.error('해당 폼번/일련번호는 이미 생산 파렛트로 등록이 되어 있습니다.')
        this.initInput()
        return
      }
      this.isSaving = true
      this.$_sp.runInsertSqlProc('생산실적', null, [param])
        .then((data) => {
          this.isSaving = false
          this.focusedRowKey = this.qrKey
          this.goodsInputListInfo.push(param)
          this.initInput()
        })
        .catch(error => {
          this.isSaving = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    initInput () {
      this.qrKey = null
      this.inputQty = null
      this.inputReQty = 0
      this.inputBadQty = 0
      this.selectedPCode = null
      this.selectedRow = {}
      setTimeout(() => {
        this.$Q('#input-qrkey').focus()
      }, 10)
    },
    async onEnterPcode (e) {
      if (this.qrKey === null || this.qrKey.length === 0) {
        return
      }
      let split = null
      split = AppLib.base64DecodeSubstring(this.qrKey).split('/')
      if (split.length === 1) {
        this.$snotify.error('일련번호를 확인할 수 없습니다.')
        return
      }
      this.qrKey = parseInt(split[1])
      this.qrPCode = split[0].replace('?', ' ')
      const pInfo = this.$_.findWhere(this.productInfoList, { 품번: this.qrPCode })
      if (pInfo === undefined) {
        this.$snotify.info('해당 품목을 검색 할 수 없습니다.')
        this.initInput()
        return
      }
      if (this.goodsType === ConstDef.완제품) {
        if (pInfo.품목유형 !== ConstDef.완제품) {
          this.$snotify.info('해당 품목은 완제품이 아닙니다.')
          this.initInput()
          return
        }
      } else {
        console.log(pInfo.품목유형)
        if (pInfo.품목유형 !== ConstDef.반제품 && pInfo.품목유형 !== ConstDef.다공정품) {
          this.$snotify.info('해당 품목은 반제품이 아닙니다.')
          this.initInput()
          return
        }
      }
      let result = true
      await this.$_sp.runNoEncodeFindSqlProc('생산실적', { 입고키: this.qrKey })
        .then((data) => {
          if (this.$_sp.MakeModel(data).length > 0) {
            result = false
          }
        })
      if (result === false && this.qrKey !== 0) {
        this.$snotify.info('해당 파레트번호는 이미 등록되어있습니다.')
        this.initInput()
        return
      }
      this.selectedRow = pInfo
      this.showQrScaner = 'none'
      this.selectedPCode = this.qrPCode
      if (pInfo.파레트적재량 !== null && pInfo.파레트적재량 > 0) {
        this.inputQty = pInfo.파레트적재량
      }
      this.$Q('#input-reqty').focus()
    },
    onEnterProductList (e) {
    },
    onEnterReQty (e) {
      if (this.inputReQty === null) return
      this.$Q('#input-qty').focus()
    },
    onEnterQty (e) {
      if (this.inputQty === undefined || this.inputQty === null || !AppLib.isNumeric(this.inputQty)) return
      if (this.qrPCode === null || this.qrPCode.length === 0) {
        this.$snotify.error('품목 정보를 스캔하여 주세요')
        return
      }
      this.$Q('#input-qrkey').focus()
      this.addInventory()
    },
    onPCodeScan (result) {
      console.log(result)
      this.qrKey = result
      this.onEnterPcode(null)
    },
    onProductListChange (pCode) {
      if (pCode === null) return
      this.selectedRow = this.$_.findWhere(this.productInfoList, { 품번: pCode })
      this.qrPCode = pCode
      this.$Q('#input-bad-qty').focus()
    },
    onRowRemoved (row) {
      this.$_sp.runDeleteSqlProc('생산실적', [{ 입고키: row.data.입고키 }])
        .then((data) => {
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshInputGoodsList () {
      this.getDateOrderDate()
      this.$_sp.runNoEncodeFindProc('spFindAllWorkOutByTypeAndToday', { 생산기준일: this.orderDate, 품목유형: this.goodsType, createid: this.userInfo.userId })
        .then((data) => {
          this.goodsInputListInfo = this.$_sp.MakeModel(data)
        })
    },
    refreshProductInfo () {
      let temp = null
      if (this.goodsType === ConstDef.완제품) {
        this.productInfoList = this.$store.getters.getGoodsList(ConstDef.완제품)
      } else {
        temp = this.$store.getters.getGoodsList(ConstDef.반제품)
        const wipList = this.$store.getters.getGoodsList(ConstDef.다공정품)
        this.productInfoList = temp.concat(wipList)
        console.log(this.productInfoList)
      }
    },
    // datepicker
    onDateInput () {
      this.$refs.dialog.save(this.date)
      setTimeout(() => {
        this.$Q('#input-qrkey').focus()
      }, 200)
    }
  }
}
</script>

<style lang="scss">

</style>
